import React from 'react';
import { Chip } from '@mui/material';
import { InternalOrderState, OrderState } from '@magistrmartin/eshop-frontend-shared';
import { AlarmOn, Archive, Close, KeyboardReturn, LocalShipping, Lock, Timer } from '@mui/icons-material';

interface IProps {
  orderStatus: OrderState | InternalOrderState;
}

const STATUS_MAP: Record<string, { label: string; icon: React.ReactElement; style?: React.CSSProperties }> = {
  Waiting:              { label: 'Čekání',              icon: <Timer />,           style: { backgroundColor: '#F1C40F', color: 'white' } },
  InProgress:           { label: 'Zabaleno',            icon: <Archive />,         style: { backgroundColor: '#E67E22', color: 'white' } },
  Shipped:              { label: 'Odesláno',            icon: <LocalShipping />,   style: undefined },
  Finished:             { label: 'Uzavřeno',            icon: <Lock />,            style: { backgroundColor: '#1D8348', color: 'white' } },
  Rejected:             { label: 'Zamítnuto',           icon: <Close />,           style: { backgroundColor: '#922B21', color: 'white' } },
  Canceled:             { label: 'Stornováno',          icon: <Close />,           style: { backgroundColor: '#922B21', color: 'white' } },
  Prepared:             { label: 'Připraveno',          icon: <AlarmOn />,         style: { backgroundColor: '#10b0a0', color: 'white' } },
  Refunded:             { label: 'Vratka',              icon: <KeyboardReturn />,  style: { backgroundColor: '#922B21', color: 'white' } },
  new:                  { label: 'Čekání',              icon: <Timer />,           style: { backgroundColor: '#F1C40F', color: 'white' } },
  fullfilled:           { label: 'Vykryto',             icon: <Archive />,         style: undefined },
  partiallyFullfilled:  { label: 'Částečně vykryto',    icon: <Archive />,         style: undefined },
  rejected:             { label: 'Zamítnuto',           icon: <Close />,           style: { backgroundColor: '#922B21', color: 'white' } },
  received:             { label: 'Přijato',             icon: <Lock />,            style: { backgroundColor: '#1D8348', color: 'white' } },
};

export default function OrderStatusLabel({ orderStatus }: IProps) {
  const status = STATUS_MAP[orderStatus];

  if (!status) {
    return null; // Handle unknown statuses
  }

  return <Chip icon={status.icon} label={status.label} style={status.style} />;
}
