import React, { useState } from 'react';
import { Order, OrderPreview } from'../Types/order';
import {
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useLayout } from '../Layout/LayoutContext';
import { ordersService } from '../Utils/ApiService';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { copyToClipboard, toCzechTime } from '../Utils/Utils';
import { OrderState } from '@magistrmartin/eshop-frontend-shared';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OrderRefunds from '../Components/OrderRefunds';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer'
    },
    link: {
      color: theme.palette.text.primary,
    },
    headerFont: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    itemFont: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    itemStyle: {
      cursor: 'pointer',
    }
    })
  );

interface OrderDeliveryDetailsProps {
    order: Order;
    orderPreview: OrderPreview;
}

interface InfoFieldProps {
  label: string;
  value: string | null | undefined;
  copyMessage: string;
  labelXs: number;
  valueXs: number;
  classes: Record<string, string>;
  copyToClipboard: (text: string) => void;
  layout: {
    info: (message: string) => void;
  };
}

interface InfoSectionProps {
  title: string;
  fields: Field[];
  labelXs: number;
  valueXs: number;
  classes: Record<string, string>;
  copyToClipboard: (text: string) => void;
  layout: {
    info: (message: string) => void;
  };
}

interface Field {
  label: string;
  value: string | null | undefined;
  copyMessage: string;
}

export default function OrderDeliveryDetails({ order, orderPreview }: OrderDeliveryDetailsProps) {
  const classes = useStyles();
  const layout = useLayout();
  const [openedRefundDialog, setopenedRefundDialog] = useState<boolean>(false);
  const [useTestEndpoint, setUseTestEndpoint] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setUseTestEndpoint(event.target.checked);
  };
  const openRefundDialog = () => {
    setopenedRefundDialog(true);
  };
  const closeRefundDialog = () => {
    setopenedRefundDialog(false);
  };

  const billingFields = [
    { label: 'Jméno', value: order.deliveryInfo.name, copyMessage: 'Jméno bylo zkopírováno do schránky.' },
    { label: 'Příjmení', value: order.deliveryInfo.surname, copyMessage: 'Příjmení bylo zkopírováno do schránky.' },
    { label: 'Ulice', value: order.deliveryInfo.street, copyMessage: 'Ulice byla zkopírována do schránky.' },
    { label: 'Číslo popisné', value: order.deliveryInfo.houseNum, copyMessage: 'Číslo popisné bylo zkopírováno do schránky.' },
    { label: 'Město', value: order.deliveryInfo.city, copyMessage: 'Město bylo zkopírováno do schránky.' },
    { label: 'PSČ', value: order.deliveryInfo.zip, copyMessage: 'PSČ bylo zkopírováno do schránky.' },
    { label: 'Firma', value: order.deliveryInfo.company, copyMessage: 'Firma byla zkopírována do schránky.' },
    { label: 'IČO', value: order.deliveryInfo.companyId, copyMessage: 'IČO bylo zkopírováno do schránky.' },
    { label: 'DIČ', value: order.deliveryInfo.vat, copyMessage: 'DIČ bylo zkopírováno do schránky.' },
  ];

  const deliveryFields = [
    { label: 'Email', value: order.deliveryInfo.email, copyMessage: 'Email byl zkopírován do schránky.' },
    { label: 'Telefon', value: order.deliveryInfo.phone, copyMessage: 'Telefon byl zkopírován do schránky.' },
    { label: 'Jméno', value: order.deliveryInfo.delName, copyMessage: 'Jméno bylo zkopírováno do schránky.' },
    { label: 'Příjmení', value: order.deliveryInfo.delSurname, copyMessage: 'Příjmení bylo zkopírováno do schránky.' },
    { label: 'Ulice', value: order.deliveryInfo.delStreet, copyMessage: 'Ulice byla zkopírována do schránky.' },
    { label: 'Číslo popisné', value: order.deliveryInfo.delHouseNum, copyMessage: 'Číslo popisné bylo zkopírováno do schránky.' },
    { label: 'Město', value: order.deliveryInfo.delCity, copyMessage: 'Město bylo zkopírováno do schránky.' },
    { label: 'PSČ', value: order.deliveryInfo.delZip, copyMessage: 'PSČ bylo zkopírováno do schránky.' },
    { label: 'Firma', value: order.deliveryInfo.delComplany, copyMessage: 'Firma byla zkopírována do schránky.' },
    { label: 'Telefon pro kurýra', value: order.deliveryInfo.delPhone, copyMessage: 'Telefon pro kurýra byl zkopírován do schránky.' },
  ];

  const formatPhoneNumber = (phoneNumber: string) => {
    // Split the numeric part into groups of 3 digits
    const groups = [];
    for (let i = 0; i < phoneNumber.length; i += 3) {
      groups.push(phoneNumber.slice(i, i + 3));
    }
    return `${groups.join(' ')}`;
  };

const InfoField = ({ label, value, copyMessage, labelXs, valueXs, classes, copyToClipboard, layout } : InfoFieldProps) => (
  <Grid container alignItems="center" minHeight="28px">
    <Grid item xs={labelXs}>
      <Typography variant="body2" className={classes.headerFont}>
        {label}:
      </Typography>
    </Grid>
    <Grid item xs={valueXs} className={classes.itemFont} container alignItems="center">
      {value && (
        <>
          <Button
            onClick={() => {
              copyToClipboard(value);
              layout.info(copyMessage);
            }}
            title="Kopírovat"
          >
            <ContentCopyIcon fontSize="small" />
          </Button>
          {label === 'Telefon' || label === 'Telefon pro kurýra' ? formatPhoneNumber(value) : value}
        </>
      )}
    </Grid>
  </Grid>
);

const InfoSection = ({ title, fields, labelXs, valueXs, classes, copyToClipboard, layout } : InfoSectionProps) => (
  <Grid container direction="column">
    <Grid item className={classes.headerFont}>
      <h3>{title}</h3>
    </Grid>
    <Grid container direction="column">
      {fields.map((field, index) => (
        <InfoField
          key={index}
          label={field.label}
          value={field.value}
          copyMessage={field.copyMessage}
          labelXs={labelXs}
          valueXs={valueXs}
          classes={classes}
          copyToClipboard={copyToClipboard}
          layout={layout}
        />
      ))}
    </Grid>
  </Grid>
);

  return(
    <Grid container direction="row">
      {/* Billing Section */}
    <Grid container xs={3.5}>
      <InfoSection
        title="Fakturační údaje"
        fields={billingFields}
        labelXs={3}
        valueXs={9}
        classes={classes}
        copyToClipboard={copyToClipboard}
        layout={layout}
      />
    </Grid>

    {/* Delivery Section */}
    <Grid container xs={4.5}>
      <InfoSection
        title="Dodací údaje"
        fields={deliveryFields}
        labelXs={4}
        valueXs={8}
        classes={classes}
        copyToClipboard={copyToClipboard}
        layout={layout}
      />
    </Grid>
      {/* History Section 3 */}
      <Grid container xs={4}>
        <Grid container direction="column" >
          <Grid item className={classes.headerFont}><h3>Historie vývoje objednávky</h3></Grid>
          <Grid container direction="row" rowGap={1}>
            <Grid container alignItems="center">
              <Grid item xs={4} className={classes.headerFont}>
                <OrderStatusLabel orderStatus="Waiting" />
              </Grid>
              <Grid item xs={8} className={classes.itemFont} container alignItems="center">
                {toCzechTime(new Date(order.createdDate || '')).toLocaleString('cs-CZ')}
              </Grid>
            </Grid>
            {order.statusChangeHistoryList.map((h) => {
              const originalDate = new Date(h.date || '');
              return (
                <Grid container alignItems="center" minHeight="28px">
                  <Grid item xs={4} className={classes.headerFont}>
                    <OrderStatusLabel orderStatus={h.newState as OrderState} />
                  </Grid>
                  <Grid item xs={8} className={classes.itemFont} container alignItems="center">
                    {toCzechTime(originalDate).toLocaleString('cs-CZ')}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item className={classes.headerFont}>
              <h3>Refundace</h3>
              {<OrderRefunds orderId={order.id} />}
            </Grid>
            <Grid item xs={12} container>
              {['InProgress', 'Shipped', 'Finished', 'Prepared', 'Waiting'].includes(orderPreview.status || '') &&
                orderPreview.paid === true && (
                  <>
                    <Button color="error" variant="outlined" onClick={openRefundDialog} sx={{padding: "8px", height: "42px"}}>
                      Refundace objednávky{' '}
                    </Button>
                    <Dialog
                      open={openedRefundDialog}
                      onClose={closeRefundDialog}
                      PaperProps={{
                        component: 'form',
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                          event.preventDefault();
                          const formData = new FormData(event.currentTarget);
                          const formJson = Object.fromEntries((formData as any).entries());
                          const refundAmount = formJson.refundAmount;
                          if (orderPreview.totalPrice && refundAmount > orderPreview.totalPrice) {
                            layout.error('Zadaná částka přesahuje cenu objednávky');
                            return;
                          }
                          const endpoint = useTestEndpoint ? '/refundTestOrder/' : '/refundOrder/';
                          ordersService.post(
                            endpoint,
                            { orderId: orderPreview.id, refundAmount: refundAmount },
                            null,
                            {
                              success: () => {
                                layout.success('Objednávka úspěšně refundována 😃');
                              },
                              error: () => {
                                layout.error('Při refundaci došlo k chybě');
                              },
                            }
                          );
                          closeRefundDialog();
                        },
                      }}
                    >
                      <DialogTitle>Refundovat</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Celková cena: {orderPreview.totalPrice && orderPreview.totalPrice.toLocaleString('cs-CZ')} Kč
                        </DialogContentText>
                        <TextField
                          autoFocus
                          required
                          margin="dense"
                          id="refundAmount"
                          name="refundAmount"
                          label="Hodnota refundace"
                          type="text"
                          variant="standard"
                          helperText="CZK"
                        />
                        <Box mt={2}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={useTestEndpoint} onChange={handleCheckboxChange} />
                            }
                            label="Použít testovací endpoint"
                          />
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeRefundDialog}>Zavřít</Button>
                        <Button type="submit">Refundovat</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
