import React, { useState, useEffect, useRef} from 'react';
import { Typography, Skeleton, List, ListItem } from '@mui/material';
import { ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';

interface Refund {
  id: number;
  amount: number;
  date: string;
}

interface Props {
  orderId?: number;
}

const OrderRefunds: React.FC<Props> = ({ orderId }) => {
  const [refunds, setRefunds] = useState<Refund[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isFirstRender = useRef(true);
  const layout = useLayout();

  useEffect(() => {

    if (isFirstRender.current)
    {
      const fetchRefunds = async () => {
        setIsLoading(true);
        ordersService.get(
          `/refunds/by-order/${orderId}`,
          {},
          {
            success: (data) => {
              setRefunds(data);
              setIsLoading(false);
            },
            error: () => {
              layout.error("Refunds for order failed to load");
              setIsLoading(false);
            },
          }
        );
      };
      fetchRefunds();
      isFirstRender.current = false;
    };

  }, [orderId, layout]);

  if (isLoading) return <Skeleton />;
  if (refunds.length === 0) return <Typography>Objednávka nemá zaznamenány žádné refundace.</Typography>;

  return (
    <>
    <List>
      {
        refunds.map((refund) => (
            <ListItem>
              {new Date(refund.date).toLocaleDateString('cs')} - {refund.amount} Kč
            </ListItem>
        ))
      }
    </List>
    </>
  );
};

export default OrderRefunds;
