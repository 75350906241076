import { Button, Grid, IconButton, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingFab from '../../Components/LoadingFab';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct, ISelectsConfig } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../../Utils/ApiService';
import { ArrowBack, Edit, NavigateBefore, NavigateNext, Pageview } from '@mui/icons-material';
import PricesEditor from './PricesEditor';
import InfoEditor from './InfoEditor';
import DetailsEditor from './DetailsEditor';
import DescriptionEditor from './DescriptionEditor';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      padding: theme.spacing(3),
    },
    saveBtn: {
      position: 'fixed',
      right: `calc(48px + ${theme.spacing(2)})`,
      bottom: theme.spacing(2),
      zIndex: 999,
    },
    nextBtn: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      zIndex: 999,
    },
    prevBtn: {
      position: 'fixed',
      right: `calc(188px + ${theme.spacing(2)})`,
      bottom: theme.spacing(2),
      zIndex: 999,
    },
    pointer: {
      cursor: 'pointer',
    },
  })
);

interface IProps {
  id?: number;
}

export interface IProductStamp {
  id: number;
  title: string;
  subtitle: string;
}

export default function EditProduct({ id }: IProps) {
  const classes = useStyles();
  const layout = useLayout();
  const [product, setProduct] = useState<IProduct | undefined>(undefined);
  const [selectsConfig, setSelectsConfig] = useState<ISelectsConfig | undefined>(undefined);
  const [allProducts, setAllProducts] = useState<IProductStamp[]>([]);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const changeProduct = (attr: keyof IProduct, val: any) => {
    if (product !== undefined) {
      const newProd = { ...product };
      newProd[attr] = val as never;
      setProduct(newProd);
    }
  };
  
  const getNeighborProdId = (offset: number) => {
    if (product !== undefined) {
      const storedOrder = JSON.parse(localStorage.getItem('currentProductsOrder') || '[]') as number[];
      if (storedOrder.length > 1) {
        const actIndex = storedOrder.indexOf(product.id);
        return storedOrder[(actIndex + offset + storedOrder.length) % storedOrder.length];
      }
      if (allProducts.length > 0) {
        const actIndex = allProducts.map((p) => p.id).indexOf(product.id);
        return allProducts[(actIndex + offset + allProducts.length) % allProducts.length].id;
      }
    }
    return product?.id || 0;
  };

  useEffect(
    () => {
      layout.setIsLoading(true);
      catalogService.get(
        'products/noauth',
        { id: id },
        {
          success: (prd: IProduct) => {
            layout.setIsLoading(false);
            setProduct(prd);
          },
          error: layout.error,
        }
      );
      catalogService.get(
        '/settings/selects/',
        {},
        {
          success: setSelectsConfig,
          error: layout.error,
        }
      );
      catalogService.get(
        'products/all',
        { fields: 'id,title,subtitle' },
        {
          success: setAllProducts,
          error: layout.error,
        }
      );
    },
    // eslint-disable-next-line
    [id]
  );
  
  return product === undefined ? (
    <></>
  ) : (
    <>
      <IconButton
        onClick={() => {
          localStorage.setItem('productsSearch', product.title || '');
          localStorage.setItem('productsPage', '1');
          history.push('/products');
        }}
      >
        <ArrowBack />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open(`https://www.pharmdata.cz/pdk_new/#f=active%3Dtrue%7Cfulltext%3D${product.id}`, '_blank')
        }
        size="large"
      >
        <Edit />
      </IconButton>
      <Typography variant="h4" noWrap={true} display="inline">
        [{product.id}]
      </Typography>{' '}
      <span
        className={classes.pointer}
        onClick={() => window.open(`https://magistrmartin.cz/product/${product.id}`, '_blank')}
      >
        &nbsp;&nbsp;&nbsp;
        <Pageview />
        <Typography variant="h4" noWrap={true} display="inline">
          {product.title}{' '}
        </Typography>
        <Typography variant="h6" noWrap={true} display="inline">
          {product.subtitle}
        </Typography>
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        color="primary"
        onClick={() =>
          catalogService.get(
            '/heureka/queryInfo',
            { productId: product.id },
            {
              success: (d: any) =>
                d?.productSlug && d?.categorySlug
                  ? window.open(`https://${d.categorySlug}.heureka.cz/${d.productSlug}`, '_blank')
                  : layout.error('Produkt nemohl být otevřen'),
              error: () => layout.error('Produkt nemohl být otevřen'),
            }
          )
        }
      >
        Heuréka
      </Button>
      <Button color="primary" onClick={() => window.open(`${product.zboziSearch}`, '_blank')}>
        Zboží
      </Button>
      <Button color="primary" onClick={() => window.open(`https://www.drmax.cz/vyhledavani?q=${product.id}`, '_blank')}>
        DR.MAX
      </Button>
      &nbsp;
      <Button color="primary" onClick={() => window.open(`https://www.benu.cz/vyhledavani?q=${product.ean}`, '_blank')}>
        BENU
      </Button>
      <Button color="primary" onClick={() => window.open(`https://nshop.pharmos.cz/Front/Details/${product.pharmosCode}`, "_blank")}>
        Pharmos
      </Button>
      <br />
      <br />
      <Grid container>
        <Grid className={classes.itemRoot} item xs={12} md={6} lg={4}>
          <InfoEditor
            changeProduct={changeProduct}
            product={product}
            setProduct={setProduct}
            selectsConfig={selectsConfig}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PricesEditor
            setProduct={setProduct}
            allProducts={allProducts}
            changeProduct={changeProduct}
            product={product}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6} lg={4}>
          <DetailsEditor
            changeProduct={changeProduct}
            product={product}
            selectsConfig={selectsConfig}
            allProducts={allProducts}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12}>
          <DescriptionEditor changeProduct={changeProduct} product={product} />
        </Grid>
      </Grid>
      <br />
      <br />
      <IconButton
        className={classes.prevBtn}
        aria-label="prev"
        color="primary"
        onClick={() => history.push(`/products/edit/${getNeighborProdId(-1)}`)}
        size="large"
      >
        <NavigateBefore />
      </IconButton>
      <LoadingFab
        color="primary"
        variant="extended"
        className={classes.saveBtn}
        label="Uložit změny"
        loadingLabel="Ukládání"
        loading={uploading}
        onClick={() => {
          setUploading(true);
          catalogService.post(
            'products/insert',
            {},
            { ...product },
            {
              success: () => {
                layout.success('Změny byly úspěšně uloženy');
                setUploading(false);
              },
              error: () => {
                layout.error('Při ukládání změn došlo k chybě');
                setUploading(false);
              },
            }
          );
        }}
      />
      <IconButton
        className={classes.nextBtn}
        aria-label="next"
        color="primary"
        onClick={() => history.push(`/products/edit/${getNeighborProdId(1)}`)}
        size="large"
      >
        <NavigateNext />
      </IconButton>
      <br />
      <br />
    </>
  );
}
