import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useLayout } from '../../Layout/LayoutContext';
import { Order } from '../../Types/order';
import { ordersService } from '../../Utils/ApiService';

interface IProps {
  order: Order;
  onReject: () => void;
}

const predefinedReasons = [
  'Velice se omlouváme, ale objednané produkty jsou momentálně na trhu ve výpadku a nejsou dlouhodobě dostupné.',
  'Velice se omlouváme, ale došlo k vyprodání produktů přes táru v lékárně a z důvodu výpadku na trhu není možné zajistit další.',
  'Velice se omlouváme, ale stále nedošlo k úhradě Vaší objednávky, a proto jsme nuceni ji zrušit.',
  'Velice se omlouváme, ale došlo k vyprodání akčního produktu.',
  'DUPLICITNÍ OBJEDNÁVKA.',
];

export default function RejectOrderModal({ order: o, onReject }: IProps) {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const layout = useLayout();

  const rejectOrder = () => {
    setOpen(false);
    ordersService.post('/changeStatus/', { orderId: o.id, newStatus: 'Rejected', reason: reason }, null, {
      success: onReject,
      error: () => layout.error('Při ukládání došlo k chybě'),
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} color="secondary" variant="contained">
        Zamítnout objednávku
      </Button>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <DialogTitle>Zamítnutí objednávky</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Předdefinované důvody</Typography>
          <Table size="small">
            <TableBody>
              {predefinedReasons.map((r, i) => (
                <TableRow key={i}>
                  <TableCell>{r}</TableCell>
                  <TableCell>
                    <Button color="primary" onClick={() => setReason(r)}>
                      Použít
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
          <TextField fullWidth label="Důvod zamítnutí" value={reason} onChange={(e) => setReason(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Zrušit
          </Button>
          <Button color="primary" disabled={reason.length === 0} onClick={rejectOrder}>
            Zamítnout objednávku
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
