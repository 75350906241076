import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ordersService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';

interface IProps {
  orderId: number;
}

export default function SendOrderDelayedMailModal({ orderId }: IProps) {
  const [open, setOpen] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(0);

  const layout = useLayout();

  const sendEmail = () => {
    ordersService.post('notifyDelay', { orderId: orderId, numberOfDays: numberOfDays }, null, {
      success: () => {
        setNumberOfDays(0);
        setOpen(false);
        layout.success('Email odeslán');
      },
      error: () => layout.error('Při ukládání došlo k chybě'),
    });
  };

  return (
    <>
      <Button
        sx={{backgroundColor: "#A8AAA9",
          '&:hover': {
            backgroundColor: "#848786",
          }
        }} 
        variant="contained" 
        onClick={() => setOpen(true)}>
        Poslat email o zpoždění
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogContent>
          <TextField
            fullWidth
            label="Počet dní"
            value={numberOfDays}
            onChange={(e) => setNumberOfDays(parseInt(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Zavřít
          </Button>
          <Button color="primary" onClick={sendEmail} disabled={numberOfDays <= 0}>
            Odeslat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
