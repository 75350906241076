import { useEffect, useState } from 'react';
import { ordersService } from '../../Utils/ApiService';
import { OrderPreview } from '../../Types/order';

const useAllOrders = (layout: any) => {
  const [orders, setOrders] = useState<OrderPreview[]>([]);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const errorText = 'Při načítání objednávek došlo k chybě';

  useEffect(() => {
    setIsLoading(true);
    ordersService.get(
      '/previews',
      {},
      {
        success: (fetchedData: OrderPreview[]) => {
          setOrders(fetchedData);
          setError(undefined);
          setIsLoading(false);
        },
        error: () => {
          setError(errorText);
          layout.error(errorText);
          setIsLoading(false);
        },
      }
    );
    // eslint-disable-next-line
  }, []);

  return { orders, setOrders, error, setError, isLoading, setIsLoading };
};

export default useAllOrders;
