import { Button } from '@mui/material';
import React from 'react';
import { OrderState } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  status?: OrderState;
  isPersonal: boolean;
  setStatus: (newStatus: OrderState) => void;
}

const getSuccessor = (status: string | undefined, isPersonal: boolean) => {
  if (isPersonal) {
    switch (status) {
      case 'Waiting':
        return 'InProgress';
      case 'InProgress':
        return 'Finished';
      case 'Shipped':
      case 'Prepared':
        return 'Finished';
      case 'Canceled':
        return 'InProgress';
      case 'Finished':
        return 'Refunded';
      default:
        return undefined;
    }
  } else {
    switch (status) {
      case 'Waiting':
        return 'InProgress';
      case 'InProgress':
        return 'Shipped';
      case 'Shipped':
      case 'Prepared':
        return 'Finished';
      case 'Canceled':
        return 'InProgress';
      case 'Finished':
        return 'Refunded';
      default:
        return undefined;
    }
  }
};

const getSuccessorText = (status: string | undefined, isPersonal: boolean) => {
  if (isPersonal) {
    switch (status) {
      case 'Waiting':
        return 'Zabaleno';
      case 'InProgress':
        return 'Uzavřeno';
      case 'Shipped':
      case 'Prepared':
        return 'Uzavřeno';
      case 'Canceled':
        return 'Zabaleno';
      case 'Finished':
        return 'Vratka';
      default:
        return undefined;
    }
  } else {
    switch (status) {
      case 'Waiting':
        return 'Zabaleno';
      case 'InProgress':
        return 'Odesláno';
      case 'Shipped':
      case 'Prepared':
        return 'Uzavřeno';
      case 'Canceled':
        return 'Zabaleno';
      case 'Finished':
        return 'Vratka';
      default:
        return undefined;
    }
  }
};

// Refactored NextOrderStatusBtn component
export default function NextOrderStatusBtn({ setStatus, status, isPersonal }: IProps) {
  const successor = getSuccessor(status, isPersonal);
  const successorText = getSuccessorText(status, isPersonal);

  return successor ? (
    <Button variant="contained" 
      sx={{backgroundColor: "#36CE53",
        '&:hover': {
          backgroundColor: "#26913b"
      }}} 
      onClick={() => setStatus(successor)}>
      Nastavit status jako "{successorText}"
    </Button>
  ) : null;
}