import {
  Button,
  Grid,
  Typography,
  Theme,
} from '@mui/material';
import { Order, OrderPreview } from '../Types/order';
import { apiUrl, ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import NextOrderStatusBtn from '../Components/NextOrderStatusBtn';
import { Tooltip, Divider } from '@mui/material';
import { copyToClipboard, round } from '../Utils/Utils';
import { ShippingPaymentConfiguration, IProduct } from '@magistrmartin/eshop-frontend-shared';
import { generateOrderPdf } from '../Utils/PdfOrderGenerator';
import { createStyles, makeStyles } from '@mui/styles';
import RejectOrderModal from '../Pages/Modals/RejectOrderModal';
import SendOrderDelayedMailModal from '../Pages/Modals/SendOrderDelayedMailModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: { 
      cursor: 'pointer' 
    },
    link: {
      color: theme.palette.text.primary,
    },
    headerFont: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    itemFont: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    itemStyle: {
      cursor: 'pointer',
    }
    })
  );

interface OrderProductsDetailsProps {
    order: Order;
    orderPreview: OrderPreview;
    onChangeStatus: (orderId: number | undefined, newStatus: string) => void;
    onSetPaid: (orderId: number | undefined, newPaid: boolean) => void;
    allProducts: IProduct[];
    deliveryPaymentConfig: ShippingPaymentConfiguration | undefined;
}

export default function OrderProductsDetails({
    order, 
    orderPreview, 
    onChangeStatus, 
    onSetPaid, 
    allProducts, 
    deliveryPaymentConfig }
    : OrderProductsDetailsProps) {
    
    const classes = useStyles();
    const layout = useLayout();
  
    // Opens Packeta label in a new tab
    const getPacketaLabel = (o: Order) => {
      const url = `${apiUrl}orders/packeta/printLabel?orderId=${o.id}`;
      window.open(url, '_blank');
    };
  
    const handleOrderProductsAmount = (productId: number) => { 
        let mmDistribuceFlag = false;
        let vykrytoFlag = false;

        const records = JSON.parse(orderPreview.stockAmount || '[]');
        const record = records.find((item: any) => item.productId === productId);
        let { remark, amount, ordered } = record;
        if (amount === null) amount = 0;
        if (remark?.includes('MM Distribuce')) {
            if (amount < ordered) mmDistribuceFlag = true;
        } else {
            const parts = remark?.split(' ');
            if ((parts?.length || 0) >= 2) {
              const numbers = parts[1].split('/').map(Number);
              if (numbers[0] < numbers[1]) vykrytoFlag = true;
            }
          }

        if (vykrytoFlag) {
          return (
            <Tooltip title="Pharmos není vykryt">
              <img src="/img/pharmos.svg" alt="pharmos" width={6} style={{ margin: '4px' }} />
            </Tooltip>
          );
        }
        if (mmDistribuceFlag) {
          return (
            <Tooltip title="MM Distribuce není vykryta">
              <img src="/img/MMDistribuce.svg" alt="MMDistribuce" width={6} style={{ margin: '4px' }} />
            </Tooltip>
          );
        }
        return null;
      };

    const stockState = (productId: number) => {
        const records = JSON.parse(orderPreview.stockAmount || '[]');
        const record = records.find((item: any) => item.productId === productId);
        if (record?.amount === null) record.amount = 0;
        
        return record?.remark?.includes('MM Distribuce')
            ? `MM Distribuce - ${record.amount} ks`
            : record?.remark;
    };

    return (
      <Grid container rowGap={3} direction="column" marginBlockStart={2} marginBlockEnd={2}>
        {/* Delivery Info */}
        {order.deliveryInfo.deliveryType === 'packeta' && (
        <Grid item>
            <Typography variant="body2">
                Odkaz na odběrové místo:{' '}
                <a className={classes.link} href={order.deliveryInfo.packetaPlaceUrl} rel="noreferrer" target="_blank">
                {order.deliveryInfo.packetaPlaceUrl}
                </a>
            </Typography>
        </Grid>
        )}

        {/* Products Header */}
        <Grid item className={classes.headerFont}>
          <h2>Objednané produkty</h2>
        </Grid>
  
        <Grid container alignItems={"center"} rowGap={1}>
        {/* Table Header */}
        <Grid container className={classes.headerFont} alignItems="center" columnGap={3}>
          <Grid item sx={{ width: '12%' }}>ID</Grid>
          <Grid item sx={{ width: '12%' }}>Hlavní název</Grid>
          <Grid item sx={{ width: '10%' }}>Vedlejší název</Grid>
          <Grid item sx={{ width: '5%', textAlign: 'center' }}>Množství</Grid>
          <Grid item sx={{ width: '6%', textAlign: 'center' }}>Cena za ks</Grid>
          <Grid item sx={{ width: '5%', textAlign: 'center' }}>Celková cena</Grid>
          <Grid item sx={{ width: '5%', textAlign: 'center' }}>Hmotnost</Grid>
          <Grid item sx={{ width: '9%', textAlign: 'center' }}>Marže</Grid>
          <Grid item sx={{ width: '12%' }}>Dostupnost</Grid>
          <Grid item sx={{ width: '2.5%' }}></Grid>
          <Divider variant="fullWidth" sx={{ width: '100%' }} />
        </Grid>
  
        {/* Product Rows */}
        {order.orderedProductsList.map((p) => (
          <Grid
            container
            className={`${classes.itemFont} ${classes.itemStyle}`}
            alignItems="center"
            columnGap={3}
            key={p.productId}
          >
            <Grid item sx={{ width: '12%' }}>
              <Button sx={{ padding: 0, minWidth: 'fit-content' }}>
                <ContentCopyIcon
                  fontSize="small"
                  onClick={() => {
                    copyToClipboard(p.productId ?? '');
                    layout.info('ID produktu bylo zkopírováno do schránky.');
                  }}
                />
              </Button>
              <Button
                style={{ textTransform: 'none', color: 'black' }}
                onClick={() => window.open(`/products/edit/${p.productId}`, '_blank')}
              >
                {p.productId}
              </Button>
            </Grid>
            <Grid item sx={{ width: '12%' }}>{p.productTitle}</Grid>
            <Grid item sx={{ width: '10%' }}>{p.productSubtitle}</Grid>
            <Grid item sx={{ width: '5%', textAlign: 'center' }}>
              {p.totalAmmount} {(p.freeAmmount || 0) > 0 && ` (z toho ${p.freeAmmount} zdarma)`}
            </Grid>
            <Grid item sx={{ width: '6%', textAlign: 'center' }}>
              {((p.finalPrice || 0) / (p.totalAmmount || 1)).toLocaleString('cs-CZ')} Kč
            </Grid>
            <Grid item sx={{ width: '5%', textAlign: 'center' }}>{p.finalPrice?.toLocaleString('cs-CZ')} Kč</Grid>
            <Grid item sx={{ width: '5%', textAlign: 'center' }}>
              {p.mass}
              {allProducts.find((pp) => pp.id === p.productId)?.productMass || 0} g
            </Grid>
            <Grid item sx={{ width: '9%', textAlign: 'center' }}>
              {p.totalGains === 0 || p.totalCosts === 0
                ? '0 Kč'
                : `${round(p.totalGains - p.totalCosts, 0).toLocaleString('cs-CZ')} Kč (${round(
                    (100 * (p.totalGains - p.totalCosts)) / p.totalGains,
                    2
                  ).toLocaleString('cs-CZ')} %)`}
            </Grid>
            <Grid item sx={{ width: '12%' }}>
              {stockState(p.productId)}
              {p.customExpiration ? (
                <>
                  <br />
                  <span style={{ color: 'red', fontWeight: 'bold' }}>EXP {p.customExpiration}</span>
                </>
              ) : p.expiration ? (
                <>
                  <br />
                  <span style={{ color: 'red', fontWeight: 'bold' }}>EXP {p.expiration}</span>
                </>
              ) : null}
            </Grid>
            <Grid item sx={{ width: '2.5%' }}>{(orderPreview.status === 'Waiting' && (orderPreview.paymentType === "pickup" || orderPreview.paid)) ? handleOrderProductsAmount(p.productId) : null}</Grid>
            <Divider variant="fullWidth" sx={{ width: '100%' }} />
          </Grid>
        ))}
        </Grid>

        {/* Action Buttons */}
        <Grid container columnGap={3} alignItems="center" justifyContent={'flex-end'} paddingInlineEnd={3}>
          {['InProgress', 'Shipped', 'Finished', 'Prepared', 'Refunded'].includes(orderPreview.status || '') &&
            orderPreview.deliveryType !== 'personalPickup' && (
              <Grid item sx={{ width: '12%' }}>
                <Button
                  sx={{backgroundColor: "#A8AAA9",
                    '&:hover': {
                    backgroundColor: "#848786",
                  }
                  }}
                  variant="contained"
                  onClick={() =>
                    window.open(`https://tracking.packeta.com/cs_CZ/?id=${order.deliveryInfo?.packetId}`, '_blank')
                  }
                >
                  Track&Trace Zásilkovna
                </Button>
              </Grid>
            )}
          {orderPreview.status === 'InProgress' && orderPreview.deliveryType !== 'personalPickup' && (
            <Grid item sx={{ width: '16%' }}>
              <Button 
                sx={{backgroundColor: "#A8AAA9",
                  '&:hover': {
                  backgroundColor: "#848786",
                }
                }} 
                variant="contained" 
                onClick={() => getPacketaLabel(order)}
                >
                  Vytisknout štítek Zásilkovny
              </Button>
            </Grid>
          )}
          <Grid item sx={{ width: '17%' }}>
            <Button
              sx={{ backgroundColor: "#A8AAA9",
                '&:hover': {
                  backgroundColor: "#848786",
                }
               }}
              variant="contained"
              onClick={() => generateOrderPdf(order, deliveryPaymentConfig)}
            >
              Stáhnout objednávku jako pdf
            </Button>
          </Grid>
          {!orderPreview.paid && orderPreview.status !== 'Rejected' && orderPreview.status !== 'Canceled' && (
            <Grid item sx={{ width: '12%' }}>
              <Button
                sx={{ backgroundColor: "#A8AAA9" }}
                onClick={() =>
                  layout.confirm(
                    'Nastavit objednávku jako zaplacenou',
                    'Opravdu chcete nastavit tuto objednávku jako zaplacenou?',
                    () =>
                      ordersService.post(
                        '/setPaid/',
                        { orderId: orderPreview.id },
                        null,
                        {
                          success: () => onSetPaid(orderPreview.id, true),
                          error: () => layout.error('Při ukládání došlo k chybě'),
                        }
                      )
                  )
                }
                color="primary"
                variant="contained"
              >
                Nastavit jako zaplaceno
              </Button>
            </Grid>
          )}
          {(orderPreview.status === 'Waiting' || orderPreview.status === 'Canceled') && (
            <Grid item sx={{ width: '12%' }}>
              <SendOrderDelayedMailModal orderId={orderPreview.id || 0} />
            </Grid>
          )}
          {orderPreview.status !== 'Canceled' && orderPreview.status !== 'Rejected' && (
            <Grid item sx={{ width: '15%' }}>
            <NextOrderStatusBtn
              isPersonal={orderPreview.deliveryType === 'personalPickup'}
              status={orderPreview.status}
              setStatus={(newState) =>
                layout.confirm('Změna statusu', 'Opravdu chcete změnit status objednávky?', () =>
                  ordersService.post(
                    '/changeStatus/',
                    { orderId: orderPreview.id, newStatus: newState },
                    null,
                    {
                      success: () => {
                        if (newState === 'InProgress' && order.deliveryInfo.email?.toLowerCase() === 'senior') {
                          layout.warning(
                            'Tato objednávka byla vytvořena pomocí služby Senior linka. V tomto případě nebude zákazníkovi odeslán žádný email a je tedy nutné seniorovi zavolat a informovat ho o možnosti vyzvednutí objednávky.'
                          );
                        }
                        onChangeStatus(orderPreview.id, newState);
                        
                        if (orderPreview.deliveryType === 'personalPickup' && !orderPreview.paid && newState === 'Finished') {
                          ordersService.post(
                            '/setPaid/',
                            { orderId: orderPreview.id },
                            null,
                            {
                              success: () => {
                                onChangeStatus(orderPreview.id, newState)
                                onSetPaid(orderPreview.id, true)
                                },
                              error: () => layout.error('Při ukládání došlo k chybě'),
                            }
                          );
                        }
                      },
                      error: () => layout.error('Při ukládání došlo k chybě'),
                    }
                  )
                )
              }
            />
          </Grid>
          )}
          {(orderPreview.status === 'Waiting' || orderPreview.status === 'InProgress') && (
            <Grid item sx={{ width: '11%' }}>
              <RejectOrderModal
                order={order}
                onReject={() =>
                  onChangeStatus(orderPreview.id, 'Rejected')
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }