import React, { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';
import { LinearProgress, ThemeProvider, StyledEngineProvider, Theme } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import NotAuthenticated from '../Pages/NotAuthenticated';
import { useLocation } from 'react-router-dom';
import { reducers } from '../Utils/Reducers';
import { createStyles, makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import csLocale from 'date-fns/locale/cs';
import { LocalizationProvider } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagesRoot: {
      padding: 0,
      margin: 0,
      position: 'absolute',
      left: 0,
      top: '73px',
      paddingTop: 16,
    },
  })
);

const themeStoredCode: string = localStorage.getItem('theme') || 'light';

const getTheme = (theme: 'dark' | 'light') => {
  return createTheme({
    palette: {
      mode: theme,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
  });
};

export default function Layout({ children, loading }: { children?: React.ReactNode; loading: boolean }) {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const openedStorageKey = 'leftMenuOpened';
  const classes = useStyles();
  const [menuOpened, setMenuOpened] = useState(JSON.parse(localStorage.getItem(openedStorageKey) || 'true') as boolean);
  const [userRoles, setUserRolse] = useState<string[]>([]);

  const location = useLocation();
  const allowedLocations = ['/orders/internal/distributor'];

  const [theme, setTheme] = useState<'dark' | 'light'>(themeStoredCode as 'dark' | 'light');

  const menuWidth = menuOpened ? 296 : 82;

  const setOpened = (value: boolean) => {
    localStorage.setItem(openedStorageKey, value.toString());
    setMenuOpened(value);
  };

  useEffect(() => {
    if (themeStoredCode === 'dark') changeAppTheme('dark');
  }, []);

  const changeAppTheme = (theme: 'dark' | 'light') => {
    document.body.style.backgroundColor = '';
    document.body.style.color = '';

    if (theme === 'dark') {
      document.body.style.backgroundColor = '#212121';
      document.body.style.color = '#fafafa';
    }

    setTheme(theme);
    localStorage.setItem('theme', theme);
  };

  if (isAuthenticated && userRoles.length === 0) {
    getIdTokenClaims().then((claims: any) => setUserRolse(claims['https://magistrmartin.cz/roles']));
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme(theme)}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale}>
          <Header />
          <>
            {loading && (
              <LinearProgress style={{ position: 'fixed', top: 73, left: menuWidth - 16, height: 4, width: '100%' }} />
            )}
            {(isAuthenticated && userRoles.includes('admin') && (
              <>
                <Navbar opened={menuOpened} setOpened={setOpened} theme={theme} changeAppTheme={changeAppTheme} />
                <div
                  className={classes.pagesRoot}
                  style={{ paddingLeft: menuWidth, width: `calc(100% - ${menuWidth}px)` }}
                >
                  {children}
                </div>
              </>
            )) ||
              (allowedLocations.map((x) => location.pathname.startsWith(x)).reduce(reducers.or, false) && (
                <div className={classes.pagesRoot}>{children}</div>
              )) || <NotAuthenticated />}
          </>
          <Footer />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
