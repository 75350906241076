import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Theme,
  Toolbar,
} from '@mui/material';
import { Order, OrderPreview } from '../Types/order';
import { catalogService, imagesService, ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { Cancel, CheckCircle } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Pagination, Tooltip, Divider } from '@mui/material';
import OrdersFilters, { IFilter, emptyFilter, filterOrders } from './Modals/OrdersFilters';
import { getKeyExtractorReversedComparer } from '../Utils/Comparers';
import { round, toCzechTime, copyToClipboard } from '../Utils/Utils';
import { ShippingPaymentConfiguration, OrderState, IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { createStyles, makeStyles } from '@mui/styles';
import { domains } from '../Utils/Mutations';
import { convertDateToDDMMYYYY } from '../Utils/Utils';
import useAllOrders from '../Hooks/Orders/useAllOrders';
import { exportOrders } from '../Utils/ProductsExport';
import OrderDeliveryDetails from '../Components/OrderDeliveryDetails';
import OrderProductsDetails from '../Components/OrderProductsDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: { cursor: 'pointer' },
    right: { float: 'right' },
    search: {
      flexGrow: 1,
      marginLeft: 16,
      marginRight: 16,
    },
    link: {
      color: theme.palette.text.primary,
    },
    headerFont: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    itemFont: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    itemStyle: {
      cursor: 'pointer',
    },
    copyButton: {
      minWidth: "24px",
      paddingLeft: "0px",
      paddingRight: "6px",
    },
  })
  );

export const getTotalGain = (o: OrderPreview) =>
  o.productsTotalGains - o.productsTotalCosts + o.additionalGains - o.additionalCosts;

export default function OrdersHistory() {
  const classes = useStyles();
  const layout = useLayout();
  const [selectedPlaceId, setSelectedPlaceId] = useState<number>(3);
  const [places, setPlaces] = useState<PickupPlace[]>([]);
  const [selectedChildRow, setSelectedChildRow] = useState<number | undefined>(undefined);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
  const [filter, setFilter] = useState<IFilter>(emptyFilter);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const { orders, setOrders } = useAllOrders(layout);

  const handleChangeStatus = (orderId: number | undefined, newStatus: string) => {
    if (!orderId || !newStatus) return;
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, status: newStatus } : order
      )
    );
    //fetch new data to keep it up to date 
    ordersService.get(
      '/noauth',
      { orderId: orderId },
      {
        success: (orderData) => {
          // Set the selected order
          setSelectedOrder(orderData);
          // Fetch additional data for each product in orderedProductsList
          orderData.orderedProductsList.forEach((product: { productId: any; }) => {
            catalogService.get(
              '/products/noauth',
              { id: product.productId,
                fields: ['customExpiration', 'expiration']
              },
              {
                success: (productData) => {
                    // Append customExpiration and expiration to the correct product in orderedProductsList
                    setSelectedOrder((prevOrder) => {
                      if (!prevOrder) return undefined;
                      return {
                        ...prevOrder,
                        orderedProductsList: prevOrder.orderedProductsList.map((p) =>
                          p.productId === product.productId
                            ? { ...p, 
                              customExpiration: productData.customExpiration ? convertDateToDDMMYYYY(productData.customExpiration) : null,
                              expiration: productData.expiration ? convertDateToDDMMYYYY(productData.expiration) : null
                            }
                            : p
                        ),
                      };
                    });
                  },
                error: () => layout.error('Při načítání customExpiration došlo k chybě'),
              }
            );
          });
        },
        error: () => layout.error('Při načítání objednávky došlo k chybě'),
      }
    );
  };

  const handleSetPaid = (orderId: number | undefined, newPaid: boolean) => {
    if (!orderId) return;
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, paid: newPaid } : order
      )
    );
  };

  const handleOrderProductsAmount = (stockAmount: string) => {
    if (!stockAmount) return;
    let mmDistribuceFlag: boolean = false;
    let vykrytoFlag: boolean = false;
    const data = JSON.parse(stockAmount);
    for (const record of data) {
      let { remark, amount, ordered } = record;
      if (amount === null) amount = 0;
      if (remark?.includes('MM Distribuce')) {
        if (amount < ordered) {
          mmDistribuceFlag = true;
        }
      }
      //remark contains "Vykryto X/Y"
      else {
        const parts = remark?.split(' ');
        if ((parts?.length || 0) >= 2) {
          const numbers = parts[1].split('/').map(Number);
          if (numbers[0] < numbers[1]) {
            vykrytoFlag = true;
          }
        }
      }
    }

    if (mmDistribuceFlag && vykrytoFlag) {
      return (
        <>
          <Tooltip title="Pharmos není vykryt">
          <img src='/img/pharmos.svg' alt="pharmos" width={6} style={{ margin: "4px" }} />
          </Tooltip>
          <Tooltip title="MM Distribuce není vykryta">
          <img src='/img/MMDistribuce.svg' alt="MMDistribuce" width={6} style={{ margin: "4px" }} />
          </Tooltip>
        </>
      );
    }
    if (vykrytoFlag) {
      return (
        <Tooltip title="Pharmos není vykryt">
         <img src='/img/pharmos.svg' alt="pharmos" width={6} style={{ margin: "4px" }} />
        </Tooltip>
      );
    }
    if (mmDistribuceFlag) {
      return (
        <Tooltip title="MM Distribuce není vykryta">
          <img src='/img/MMDistribuce.svg' alt="MMDistribuce" width={6} style={{ margin: "4px" }} />
        </Tooltip>
      );
    }
    return null;
  };

  const changeChildRow = (id: number | undefined) => {
    if (id !== selectedChildRow) {
      setSelectedChildRow(id);
      setSelectedOrder(undefined);
      if (id !== undefined) {
        ordersService.get(
          '/noauth',
          { orderId: id },
          {
            success: (orderData) => {
              // Set the selected order
              setSelectedOrder(orderData);
              // Fetch additional data for each product in orderedProductsList
              orderData.orderedProductsList.forEach((product: { productId: any; }) => {
                catalogService.get(
                  '/products/noauth',
                  { id: product.productId,
                    fields: ['customExpiration', 'expiration']
                  },
                  {
                    success: (productData) => {
                        // Append customExpiration and expiration to the correct product in orderedProductsList
                        setSelectedOrder((prevOrder) => {
                          if (!prevOrder) return undefined;
                          return {
                            ...prevOrder,
                            orderedProductsList: prevOrder.orderedProductsList.map((p) =>
                              p.productId === product.productId
                                ? { ...p, 
                                  customExpiration: productData.customExpiration ? convertDateToDDMMYYYY(productData.customExpiration) : null,
                                  expiration: productData.expiration ? convertDateToDDMMYYYY(productData.expiration) : null
                                }
                                : p
                            ),
                          };
                        });
                      },
                    error: () => layout.error('Při načítání customExpiration došlo k chybě'),
                  }
                );
              });
            },
            error: () => layout.error('Při načítání objednávky došlo k chybě'),
          }
        );
      }
    } else {
      setSelectedChildRow(undefined);
      setSelectedOrder(undefined);
    }
  };

  useEffect(() => {
    catalogService.get(
      '/products/all',
      { fields: ['id', 'productMass'] },
      {
        success: setAllProducts,

        error: () => layout.error('Při načítání hmotností produktů došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    imagesService.get(
      '/text',
      {
        file: domains[0].replace('.', '_') + '_shippingPayment.json',
        location: 'config',
      },
      {
        success: setDeliveryPaymentConfig,
        error: () => layout.error('Při načítání cen dopravy a platby došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ordersService.get(
      '/infrastructure/pickupPlaces',
      {},
      {
        success: setPlaces,
        error: () => layout.error('Při načítání odběrových míst došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  const rowsPerPage = 25;

  const removeDiacritics = (str: string) => {
    return str
      .normalize('NFD') // Decompose characters into base characters and diacritics
      .replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
  };
  
  const searchFilter = (o: OrderPreview) => {
    const s = search.toLowerCase();
    const fullName = removeDiacritics(`${o.name || ''} ${o.surname || ''}`
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .trim()
        .toLowerCase()
      );
    const affectedCols = [o.id?.toString() || '', removeDiacritics(o.name || ''), removeDiacritics(o.surname || ''), fullName];
    return affectedCols.reduce((acc, act) => acc || act.toLowerCase().includes(s), false);
  };

  let filteredOrders = orders || [];

  filteredOrders = filterOrders(orders, filter);

  if (search.length > 0) filteredOrders = filteredOrders.filter(searchFilter);

  filteredOrders = filteredOrders.sort(getKeyExtractorReversedComparer('id'));

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={9} item>
          <Autocomplete
            value={{
              id: selectedPlaceId,
              label: places.find((p) => p.id === selectedPlaceId)?.name || '',
            }}
            onChange={(e, val) => val?.id && setSelectedPlaceId(val.id)}
            options={places.map((p) => ({ id: p.id, label: p.name }))}
            fullWidth
            isOptionEqualToValue={(v, o) => v.id === o.id}
            renderInput={(p) => <TextField label="Lékárna" {...p} />}
          />
        </Grid>
        <Grid xs={12} md={3} item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deliveryPaymentConfig && exportOrders(orders, deliveryPaymentConfig)}
            fullWidth
          >
            Exportovat do excelu
          </Button>
        </Grid>
      </Grid>
      <Toolbar>
        <OrdersFilters filter={filter} setFilter={setFilter} />
        <TextField
          variant="standard"
          margin="dense"
          className={classes.search}
          label="Vyhledat"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Pagination
          count={Math.ceil(filteredOrders.length / rowsPerPage)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, val) => setPage(val)}
          color="primary"
        />
      </Toolbar>
      <br />

      <Grid container rowGap={1.4} paddingInlineStart={3} paddingInlineEnd={3}>
        <Grid container className={classes.headerFont} alignItems={'center'} columnGap={2.7} >
          <Grid item sx={{ width: "12%"}}>Klient</Grid>
          <Grid item sx={{ width: "6%", textAlign: "center"}}>ID</Grid>
          <Grid item sx={{ width: "8%"}}>Datum</Grid>
          <Grid item sx={{ width: "7.2%"}}>Doprava</Grid>
          <Grid item sx={{ width: "8.5%"}}>Platba</Grid>
          <Grid item sx={{ width: "5%", textAlign: "center"}}>Hmotnost</Grid>
          <Grid item sx={{ width: "8.5%", textAlign: "center"}}>Status</Grid>
          <Grid item sx={{ width: "5%", textAlign: "center"}}>Zaplaceno</Grid>
          <Grid item sx={{ width: "6.5%", textAlign: "center"}}>Cena</Grid>
          <Grid item sx={{ width: "4.5%", textAlign: "center"}}>Zisk</Grid>
          <Grid item sx={{ width: "5%", textAlign: "center"}}>Marže</Grid>
          <Grid item sx={{ width: "2.5%"}}></Grid>
          <Divider variant="fullWidth" sx={{ width: "100%", marginBlockStart: "6px" }} />
        </Grid>
        {filteredOrders
            .slice((page - 1) * rowsPerPage, Math.min(rowsPerPage * page, filteredOrders.length))
            .map((o) => (
              <>
              <Grid container className={`${classes.itemFont} ${classes.itemStyle}`} alignItems={'center'} columnGap={2.7} onClick={() => changeChildRow(o.id)}>
                <Grid item sx={{ width: "12%" }}>
                  {o.domain}
                  <br />
                  {o.name && o.surname && (
                    <>
                      <Button
                      className={classes.copyButton}
                      onClick={() => {
                        copyToClipboard(o.name + ' ' + o.surname);
                        layout.info('Jméno a příjmení bylo zkopírováno do schránky.');
                      }}
                      title="Kopírovat"
                    >
                      <ContentCopyIcon fontSize="small" />
                    </Button>
                    {o.name + " " + o.surname}
                    </>
                  )}
                </Grid>
                <Grid item sx={{ width: "6%"}}>
                  <Button
                      className={classes.copyButton}
                      onClick={() => {
                        copyToClipboard(o.id?.toString() || '');
                        layout.info('ID objednávky bylo zkopírováno do schránky.');
                      }}
                      title="Kopírovat"
                    >
                      <ContentCopyIcon fontSize="small" />
                    </Button>
                    {o.id}
                </Grid>
                <Grid item sx={{ width: "8%"}}>
                  {toCzechTime(new Date(o.createdDate || '')).toLocaleString('cs-CZ', { weekday: 'short' }).toLocaleUpperCase()}{' '}
                  {toCzechTime(new Date(o.createdDate || '')).toLocaleDateString('cs-CZ')}
                  <br />
                  {toCzechTime(new Date(o.createdDate || '')).toLocaleTimeString('cs-CZ')}
                </Grid>
                <Grid item sx={{ width: "7.2%"}}>
                  {
                    //@ts-ignore
                    deliveryPaymentConfig?.shippingPrices[o.deliveryType]?.label
                  }
                  <br />
                  {o.deliveryPrice} Kč
                </Grid>
                <Grid sx={{ width: "8.5%"}}>
                  {
                    //@ts-ignore
                     deliveryPaymentConfig?.paymentPrices[o.paymentType].label
                  }
                  <br />
                  {o.paymentPrice} Kč
                </Grid>
                <Grid item sx={{ width: "5%", textAlign: "center"}}>{o.mass} g</Grid>
                <Grid item sx={{ width: "8.5%", textAlign: "center"}}><OrderStatusLabel orderStatus={o.status as OrderState} /></Grid>
                <Grid item sx={{ width: "5%", textAlign: "center"}}>
                    {o.paid && o.status && !['Canceled', 'Rejected', 'Refunded'].includes(o.status as string) ? (
                      <CheckCircle color={'primary'} />
                    ) : (
                      <Cancel color={o.paymentType === 'pickup' ? 'disabled' : 'error'} />
                    )}
                </Grid>
                <Grid item sx={{ width: "6.5%", textAlign: "center"}}>
                  <Button
                    className={classes.copyButton}
                    onClick={() => {
                      copyToClipboard(o.totalPrice?.toString() || '');
                      layout.info('Celková cena byla zkopírována do schránky.');
                    }}
                    title="Kopírovat"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </Button>
                  {o.totalPrice?.toLocaleString('cs-CZ')}&nbsp;Kč
                  </Grid>
                <Grid item sx={{ width: "4.5%", textAlign: "center"}}>
                  {round(getTotalGain(o), 0).toLocaleString('cs-CZ')}&nbsp;Kč
                  {o.paidWithCard === false 
                  && o.deliveryType !== 'personalPickup' 
                  && o.paymentType === 'pickup' 
                  && o.status !== 'Refunded' && (
                      <>
                        <br />
                        <span style={{ color: 'green' }}>
                          +{' '}
                          {round((o.totalPrice || 0) * 0.79 * 0.01 * (deliveryPaymentConfig?.paymentCosts.pickup.packeta.relative ?? 0), 2)}
                          &nbsp;Kč
                        </span>
                      </>
                    )}
                </Grid>
                <Grid item sx={{ width: "5%", textAlign: "center"}}>
                  {
                    (o.productsTotalCosts === 0 || o.productsTotalGains === 0)
                    ? "0 %"
                    : `${round((100 * getTotalGain(o)) / (o.productsTotalGains + o.additionalGains), 2).toLocaleString('cs-CZ')}\u00A0%`
                  }
                </Grid>
                <Grid item sx={{ width: "2.5%"}}>{(o.status === 'Waiting' && (o.paymentType === "pickup" || o.paid)) ? handleOrderProductsAmount(o.stockAmount) : null}</Grid>
                <Divider variant="fullWidth" sx={{ width: '100%', marginBlockStart: "5px" }} />
              </Grid>
              {selectedChildRow === o.id && selectedOrder && o && (
                <>
                  <OrderDeliveryDetails order={selectedOrder} orderPreview={o} />
                  <OrderProductsDetails 
                    order={selectedOrder} 
                    orderPreview={o}
                    onChangeStatus={handleChangeStatus}
                    onSetPaid={handleSetPaid} 
                    allProducts={allProducts} 
                    deliveryPaymentConfig={deliveryPaymentConfig} 
                    />
                </>
              )}
              </>
            )
          )}
      </Grid>
      <br />
      <Pagination
        count={Math.ceil(filteredOrders.length / rowsPerPage)}
        showFirstButton
        showLastButton
        page={page}
        onChange={(_, val) => setPage(val)}
        color="primary"
        sx={{marginBlockEnd: "10px"}}
      />
    </>
  );
}
